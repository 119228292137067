import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import css from './Photo.module.scss';
import defaultImg from '../../../img/person-24px.svg';

const Photo = ({ id, img, editable, accept, fileChange, width }) => {
  const [image, setImage] = useState(img);
  const classes = [css.Photo];

  if (editable) {
    classes.push(css['is-editable']);
  }

  useEffect(() => {
    if (img) {
      setImage(img);
    }
  }, [img]);

  return (
    <label
      htmlFor={id}
      className={classes.join(' ')}
      style={{
        background: `white url(${image || defaultImg}) center/cover no-repeat`,
        width,
      }}>
      {editable && (
        <React.Fragment>
          <input
            id={id}
            className='d-none'
            type='file'
            accept={accept}
            onChange={(event) => {
              const { target } = event;
              if (target.files && target.files.length > 0) {
                fileChange(target.id, target.files[0]);
                setImage(URL.createObjectURL(target.files[0]));
              }
            }}
          />

          <div className={`${css['Photo-overlay']} flex-completely-centered`}>
            {/* prettier-ignore */}
            <svg xmlns='http://www.w3.org/2000/svg' height='24' viewBox='0 0 24 24' width='24'><path d='M0 0h24v24H0z' fill='none' /><path fill='white' d='M19.35 10.04C18.67 6.59 15.64 4 12 4 9.11 4 6.6 5.64 5.35 8.04 2.34 8.36 0 10.91 0 14c0 3.31 2.69 6 6 6h13c2.76 0 5-2.24 5-5 0-2.64-2.05-4.78-4.65-4.96zM14 13v4h-4v-4H7l5-5 5 5h-3z' /></svg>
          </div>
        </React.Fragment>
      )}
    </label>
  );
};

Photo.propTypes = {
  id: PropTypes.string.isRequired,
  img: PropTypes.string,
  editable: PropTypes.bool,
  accept: PropTypes.string,
  fileChange: PropTypes.func,
  width: PropTypes.string,
};

Photo.defaultProps = {
  img: '',
  editable: false,
  accept: 'image/*',
  fileChange: () => null,
  width: '',
};

export default Photo;
