import React from 'react';
import css from './Information.module.css';
import Form from 'react-bootstrap/Form';

const ListPlayers = ({row}) => {
  return (
    <div className='col-12'>
      {row.number + ' ' + row.name}
    </div>
    );
}

const VideoInformation = React.memo(({info, activateWork, activity, disableIniciarVideo}) => {
  const date = info ? new Date(info.information_general.date) : null;

  return (
    <div className={`${css.Information} pt-3`}>
      <div className="text-white text-sm mb-3 custom-bg-primary shadow-dp3 text-white py-2 px-4">
        <div className='d-flex align-items-center'>
          <p className={`cursor-pointer ${activity ? css.opaque : 'font-weight-bold'} m-0`} onClick={activateWork}>Fuera de servicio</p>
          <Form.Check
            className='text-white mx-3'
            label=''
            type='switch'
            id='recordingActivity'
            onChange={activateWork}
            checked={activity}
          />
          <p className={`cursor-pointer ${!activity ? css.opaque : 'font-weight-bold'} m-0`} onClick={activateWork}>Iniciar tu grabación</p>
        </div>

        <p className='text-sm text-center text-success mb-0 my-2'>
          {activity ? 'Salir del aire para detener grabación.' : 'Entrar al aire para iniciar grabación' }
        </p>
        <p className='text-sm text-center text-success mb-0 my-2'>
           {info ? 'Videos en cola de narración/relato ' +info.video_pending : ''}
        </p>

        <p className='text-sm text-center text-success mb-0 my-2'>
           {info ? 'Videos en asignados ' +info.video_assigned : ''}
        </p>
      </div>

      <div className='custom-bg-primary shadow-dp3 text-white'>
        <header className='py-1 px-3 custom-bg-accent text-sm'>
          <p className='text-white text-center m-0 font-weight-bold'>Información general</p>
        </header>

        <div className="px-3 py-2 text-sm">
          <div className="d-flex">
            <div className="col pl-0">
              <p className='font-weight-bold m-0'>Fecha:</p>
              <p className='font-weight-bold text-success mb-1'>{date ? `${date.getDate()}/${date.getMonth()}/${date.getFullYear()}` : '-'}</p>
            </div>

            <div className="col px-0">
              <p className='font-weight-bold m-0'>Liga:</p>
              <p className='font-weight-bold text-success mb-1'>
                {info && info.information_general.liga ? info.information_general.liga : '-'}
              </p>
            </div>
            
          </div>

          <p className='font-weight-bold m-0'>Equipos:</p>
          <p className='font-weight-bold text-success mb-0'>
            {info && info.information_general.teams ? info.information_general.teams : '-'}
          </p>

          <p className='font-weight-bold m-0'>Idioma:</p>
          <p className='font-weight-bold text-success mb-0'>
          {info && info.language ? info.language : '-'}
          </p>
        </div>
      </div>

      <div className='custom-bg-primary shadow-dp3 text-white mt-3'>
        <header className='py-1 px-3 custom-bg-accent text-sm'>
          <p className='text-white text-center m-0 font-weight-bold'>Datos de la jugada:</p>
        </header>

        <div className="px-3 py-2 text-sm">
          <p className='font-weight-bold m-0'>Equipo:</p>
          <p className='font-weight-bold text-success mb-1'>
            {info && info.play_data.team ? info.play_data.team : '-'}
          </p>

          <div className="d-flex">
            <div className="col pl-0">
              <p className='font-weight-bold m-0'>Jugador (es):</p>
              <p className='font-weight-bold text-success mb-1'>
                {info && info.play_data.player ? info.play_data.player : '-'}
              </p>
            </div>

            <div className="col px-0">
              <p className='font-weight-bold m-0'>Número:</p>
              <p className='font-weight-bold text-success mb-1'>
                {info && info.play_data.number ? info.play_data.number : '-'}
              </p>
            </div>
          </div>

          <div className="d-flex">
            <div className="col pl-0">
              <p className='font-weight-bold m-0'>Posición:</p>
              <p className='font-weight-bold text-success mb-1'>
                {info && info.play_data.position ? info.play_data.position : '-'}
              </p>
            </div>

            <div className="col px-0">
              <p className='font-weight-bold m-0'>Tipo de jugada:</p>
              <p className='font-weight-bold text-success mb-1'>
                {info && info.play_data.type_play ? info.play_data.type_play : '-'}
              </p>
            </div>
          </div>

          <p className='font-weight-bold m-0'>Descripción:</p>
          <p className='font-weight-bold text-success mb-1'>
            {info && info.play_data.description ? info.play_data.description : '-'}
          </p>

          <p className='font-weight-bold m-0'>Momento:</p>
          <p className='font-weight-bold text-success mb-0'>
            {info && info.play_data.moment ? info.play_data.moment : '-'}
          </p>
        </div>
      </div>

      {info && info.sponsor ? (
        <div className='custom-bg-primary shadow-dp3 text-white mt-3'>
          <header className='py-1 px-3 custom-bg-accent text-sm'>
            <p className='text-white text-center m-0 font-weight-bold'>Patrocinadores:</p>
          </header>

          <div className="p-3">
            {info && info.sponsor && info.sponsor.text ? (
              <p className={css.Sponsor}>{info.sponsor.text}</p>
            ) :( 
              <div className={css.Sponsor} style={{ backgroundImage: `url(${info.sponsor.logo})` }} />
            )}
          </div>
        </div>
      ) : null}

      {info && info.players ? (
        <div className='custom-bg-primary shadow-dp3 text-white mt-3'>
          <header className='py-1 px-3 custom-bg-accent text-sm'>
            <p className='text-white text-center m-0 font-weight-bold'>Jugadores:</p>
          </header>

            <div className={`${css.players} row pl-2`}>

              <div className='col-6'>
                
                <p className='text-success mb-0'>{info.players.nameA}</p>
                

                <div className='row'>
                  {info?.players?.teamA?.map((row, i) => <ListPlayers key={i} row={row} />)}
                 
                </div>
              </div>
              <div className='col-6'>
                
              <p className='text-success mb-0'>{info.players.nameB}</p>

                <div className='row'>
                {info?.players?.teamB?.map((row, i) => <ListPlayers key={i} row={row} />)}
                 
                </div>                
              </div>
            
            </div>
        </div>
      ) : null}

    </div>
  );
});

export default VideoInformation;
