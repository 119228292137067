import React from 'react';

const BoxInfo = ({ color, title, value }) => {
  return (
    <div className='custom-bg-primary shadow-dp3 text-white'>
      <header className='py-1 px-3 custom-bg-accent text-sm'>
        <p className='text-white text-center m-0 font-weight-bold'>{title || '-'}</p>
      </header>

      <p
        className='text-center px-3 py-3 font-weight-bold fs-30px'
        style={{ color: color || 'white' }}>
        {value || '0'}
      </p>
    </div>
  );
};

export default BoxInfo;
