import React from 'react';
import { PieChart } from 'react-minimal-pie-chart';
import css from './Chart.module.scss';

const PaymentsChart = ({ data }) => {
  return (
    <div className='custom-bg-primary shadow-dp3 text-white'>
      <header className='py-1 px-3 custom-bg-accent text-sm'>
        <p className='text-white text-center m-0 font-weight-bold'>
          Información general de mis narraciones
        </p>
      </header>

      <div className='py-4 px-3 d-flex align-items-center'>
        <div className='position-relative'>
          <div className={css.Earnings}>
            <p className={css['Earnings-label']}>GANANCIAS TOTALES</p>
            <p className={css['Earnings-amount']}>$345,500.00</p>
          </div>

          <PieChart
            style={{ width: '230', height: '230' }}
            labelPosition={87}
            lineWidth={25}
            label={({ dataEntry }) => `${dataEntry.value}%`}
            paddingAngle={1}
            labelStyle={{
              fontFamily: 'sans-serif',
              fontSize: '5px',
              fill: 'white',
            }}
            data={[
              { title: 'Pagados', value: 65, color: '#E4C263' },
              { title: 'Pendientes', value: 35, color: '#62BFF7' },
            ]}
          />
        </div>

        <div className='px-4 text-center text-md'>
          <p className='m-0 text-blue'>+$1,585</p>
          <p className='m-0 text-accent text-white opacity-5'>Pagados</p>
          <div className='circle sm custom-bg-blue mx-auto my-1' />
          <p className='m-0'>7</p>

          <p className='m-0 text-orange mt-3'>+$1,585</p>
          <p className='m-0 text-accent text-white opacity-5'>Pendientes</p>
          <div className='circle sm custom-bg-orange mx-auto my-1' />
          <p className='m-0'>7</p>
        </div>
      </div>
    </div>
  );
};

export default PaymentsChart;
