import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import Photo from './Photo/Photo';
import appAxios from "../../axios";


/**
 * react-hook-form DOCS
 * https://react-hook-form.com/api
 */
const ProfileForm = ({ userData, toggleEditMode, handleUserFetch }) => {
  const { register, handleSubmit, reset, setValue, errors } = useForm({
    defaultValues: {
      name: '',
      bankAccount: '',
      image: null,
    },
  });

  // function for submit data with HTTP request
  const onSubmit = (data) => {
    var formData = new FormData()

    formData.append('name',data.name);
    formData.append('bankAccount',data.bankAccount);
    formData.append('image',data.image);

    const config = {
      headers: { 'content-type': 'multipart/form-data' }
    }
    appAxios
        .post('profile',formData,config)
        .then(response => {
            toggleEditMode();
            handleUserFetch();

        })
        .catch(error => {
          
        });
  };

  // set initial data for edition mode
  useEffect(() => {
    if (userData) {
      reset({
        name: userData.name,
        bankAccount: userData.bankAccount,
      });
    }
  }, [reset, userData]);

  // register image field
  useEffect(() => {
    register('image');
  }, [register]);

  return (
    <form className='d-flex px-3 py-4' onSubmit={handleSubmit(onSubmit)}>
      <Photo
        id='image'
        img={userData && userData.imageUrl}
        editable
        fileChange={(id, file) => setValue(id, file)}
      />

      <div className='pl-4'>
        <div className='form-group'>
          <label htmlFor='username'>Nombre</label>
          <input className='form-control' name='name' ref={register} id='username' />
        </div>

        <div className='form-group'>
          <label htmlFor='userEmail'>Cuenta de Banco</label>
          <input
            className={`form-control no-buttons ${errors.bankAccount && 'is-invalid'}`}
            id='userEmail'
            name='bankAccount'
            type='number'
            ref={register({
              maxLength: {
                value: 16,
                message: 'El máximo de carácteres es 16 digitos',
              },
            })}
          />

          {errors.bankAccount && (
            <p className='invalid-feedback text-sm mt-2 mb-0'>
              {errors.bankAccount && errors.bankAccount.message}
            </p>
          )}
        </div>

        <footer className='mt-4'>
          <button
            className='text-white border-0 font-weight-bold text-md py-2 px-3 mr-2 bg-transparent'
            type='button'
            onClick={toggleEditMode}>
            Cancel
          </button>

          <button
            className='custom-bg-accent text-white border-0 font-weight-bold text-md py-2 px-3'
            type='submit'>
            Save
          </button>
        </footer>
      </div>
    </form>
  );
};

export default ProfileForm;
