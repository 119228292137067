import React from 'react';

const PaymentsTable = ({ data }) => {
  return (
    <div className='pl-4'>
      <table className='table custom-table text-white'>
        <thead>
          <tr>
            <th className='font-weight-normal'>Fecha</th>
            <th className='font-weight-normal'>Estado</th>
            <th className='font-weight-normal'>Ganancias</th>
          </tr>
        </thead>

        <tbody>
          <tr className='custom-bg-primary shadow-dp2'>
            <td className='py-3'>10/Abril/2020</td>
            <td className='py-3'>
              <div className='d-flex align-items-center'>
                <div className='md circle custom-bg-success mr-3' />
                Pago recibido
              </div>
            </td>
            <td className='py-3'>$300.00</td>
          </tr>

          <tr className='custom-bg-primary shadow-dp2'>
            <td className='py-3'>10/Abril/2020</td>
            <td className='py-3'>
              <div className='d-flex align-items-center'>
                <div className='md circle custom-bg-orange mr-3' />
                Pago pendiente
              </div>
            </td>
            <td className='py-3'>
              <span className='opacity-5'>$300.00</span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default PaymentsTable;
