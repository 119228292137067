import React from 'react';
import Header from '../Header/Header';
import PaymentsChart from './Chart/Chart';
import PaymentsTable from './Table';

const Payments = (props) => {
  return (
    <div className='min-h-screen custom-bg-secondary'>
      <Header />

      <div className='max-w-1200px mx-auto d-flex p-4 align-items-start'>
        <PaymentsChart />
        <PaymentsTable />
      </div>
    </div>
  );
};

export default Payments;
