import React, { useState, useEffect, useCallback } from 'react';
import Header from '../Header/Header';
import Profile from './Profile';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import { DateUtils } from 'react-day-picker';
import { Row, Col } from 'react-bootstrap';
import BoxInfo from './InfoBox';
import css from './Statistics.module.css';
import dateFnsFormat from 'date-fns/format';
import dateFnsParse from 'date-fns/parse';
import appAxios from "../../axios";


function parseDate(str, format, locale) {
  const parsed = dateFnsParse(str, format, new Date(), { locale });
  if (DateUtils.isDate(parsed)) {
    return parsed;
  }
  return undefined;
}

function formatDate(date, format, locale) {
  return dateFnsFormat(date, format, { locale });
}

const FORMAT = 'dd/MM/yyyy';

const Statistics = (props) => {
  const [userData, setUserData] = useState(null);
  const [reportData, setReportData] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [filters, setFilters] = useState({
    filterBy: 'week',
    startDate: '',
    endDate: '',
  });

  const handleUserFetch = useCallback(async () => {
    // params for fetchData
    // FETCH DATA, and setUserdata
    const result = await appAxios.get('/profile');
    setUserData(result.data);

    const report = await appAxios.get('/report',{
      params:filters
    });

    setReportData(report.data)


  }, [filters]);

  function toggleEditMode() {
    setEditMode((state) => !state);
  }

  function handleFilterChange(e) {
    e.persist();
    setFilters((state) => ({ ...state, [e.target.value]: e.target.id }));

  }



  useEffect(() => {
    handleUserFetch();
  }, [handleUserFetch]);

  return (
    <div className='min-h-screen custom-bg-secondary'>
      <Header />

      <div className='max-w-1200px mx-auto d-flex p-4 align-items-start'>
        <Profile userData={userData} editMode={editMode} toggleEditMode={toggleEditMode} handleUserFetch={handleUserFetch} />

        <div className='pl-3 width-full'>
          <div className={`align-items-center ${css.Filters} mb-4`}>
            <div className='d-flex align-items-center text-white mb-3'>
              <p className='m-0 text-sm mr-2 font-weight-bold'>Filtrar por:</p>
              <button
                className={`${css.FilterButton} mr-3 ${
                  filters.filterBy === 'week' && css['is-active']
                }`}
                type='button'
                onClick={handleFilterChange}
                value='filterBy'
                id='week'>
                ESTA SEMANA
              </button>

              <button
                className={`${css.FilterButton} mr-3 ${
                  filters.filterBy === 'lastWeek' && css['is-active']
                }`}
                type='button'
                onClick={handleFilterChange}
                value='filterBy'
                id='lastWeek'>
                ÚLTIMA SEMANA
              </button>

              <button
                className={`${css.FilterButton} mr-3 ${
                  filters.filterBy === 'month' && css['is-active']
                }`}
                type='button'
                onClick={handleFilterChange}
                value='filterBy'
                id='month'>
                MES ACTUAL
              </button>

              <button
                className={`${css.FilterButton} ${
                  filters.filterBy === 'lastMonth' && css['is-active']
                }`}
                type='button'
                onClick={handleFilterChange}
                value='filterBy'
                id='lastMonth'>
                MES PASADO
              </button>
            </div>

            <div className='d-flex align-items-center'>
              {/* https://react-day-picker.js.org/examples/input/ */}
              <p className='m-0 text-sm mr-2 font-weight-bold text-white'>Desde:</p>
              <DayPickerInput
                placeholder='dd/mm/yyyy'
                formatDate={formatDate}
                format={FORMAT}
                parseDate={parseDate}
                onDayChange={(day) =>
                  setFilters((state) => ({ ...state, startDate: day.toISOString() }))
                }
                inputProps={{ className: css.PickerInput }}
              />

              <p className='m-0 text-sm ml-3 mr-2 font-weight-bold text-white'>Hasta:</p>
              <DayPickerInput
                placeholder='dd/mm/yyyy'
                formatDate={formatDate}
                format={FORMAT}
                parseDate={parseDate}
                onDayChange={(day) =>
                  setFilters((state) => ({ ...state, endDate: day.toISOString() }))
                }
                inputProps={{ className: css.PickerInput }}
              />
            </div>
          </div>

          <Row>
            <Col md={4} sm={6} xs={12}>
              <BoxInfo color='#4DE998' title='Videos narados español' value={reportData ? reportData["pending-validation-es"] : 0} />
            </Col>

            <Col md={4} sm={6} xs={12}>
              <BoxInfo color='#4DE998' title='Videos narados ingles' value={reportData ? reportData["pending-validation-en"] : 0} />
            </Col>

            <Col md={4} sm={6} xs={12}>
              <BoxInfo color='#3DC1FC' title='Videos aprobados' value={reportData ? reportData.release : 0} />
            </Col>

            <Col md={4} sm={6} xs={12}>
              <BoxInfo color='#FC4044' title='Videos rechazados español' value={reportData ? reportData["rejected-narration-es"] : 0} />
            </Col>

            <Col md={4} sm={6} xs={12}>
              <BoxInfo color='#FC4044' title='Videos rechazados ingles' value={reportData ? reportData["rejected-narration-en"] : 0} />
            </Col>
          </Row>

          <Row>
            <Col md={4} sm={6} xs={12}>
              <BoxInfo title='Usuarios reportados' value={reportData ? reportData["report-user"] : 0} />
            </Col>

            <Col md={4} sm={6} xs={12}>
              <BoxInfo title='Reportes confirmados' value={reportData ? reportData["report-user-accepted"] : 0} />
            </Col>

            <Col md={4} sm={6} xs={12}>
              <BoxInfo title='Ganancias' value={0} />
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default Statistics;
