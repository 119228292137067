import React from 'react';
import Photo from './Photo/Photo';
import ProfileForm from './Form';

const Profile = ({ userData, editMode, toggleEditMode, handleUserFetch }) => {
  return (
    <section className='custom-bg-primary shadow-dp3 text-white min-w-360px'>
      <header className='py-1 px-3 custom-bg-accent text-sm'>
        <p className='text-white text-center m-0 font-weight-bold'>Información general</p>
      </header>

      {editMode ? (
        <ProfileForm userData={userData} toggleEditMode={toggleEditMode} handleUserFetch={handleUserFetch} />
      ) : (
        <div className='py-4'>
          <div className='d-flex px-3'>
            <Photo id='image' img={userData && userData.imageUrl} />

            <div className='pl-4 text-md'>
              <p className='mb-0 font-weight-bold'>{(userData && userData.name) || '-'}</p>
              <p className='mb-3 opacity-5'>{(userData && userData.email) || '-'}</p>

              <p className='mb-0 font-weight-bold'>Cuenta de banco</p>
              <p className='mb-0 opacity-5'>{(userData && userData.bankAccount) || '-'}</p>
            </div>
          </div>

          <button
            className='mx-auto d-block custom-bg-accent text-white border-0 font-weight-bold text-md mt-4 px-5 py-2'
            onClick={toggleEditMode}
            type='button'>
            EDITAR
          </button>
        </div>
      )}
    </section>
  );
};

export default Profile;
