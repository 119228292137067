import React, { useState, useEffect, useCallback } from 'react';
import Header from '../Header/Header';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import { DateUtils } from 'react-day-picker';
import { Row, Col } from 'react-bootstrap';
import css from './Reviews.module.css';
import dateFnsFormat from 'date-fns/format';
import dateFnsParse from 'date-fns/parse';
import appAxios from "../../axios";
import BoxInfo from './InfoBox';
import Paginate from 'react-bootstrap/Pagination';

function parseDate(str, format, locale) {
  const parsed = dateFnsParse(str, format, new Date(), { locale });
  if (DateUtils.isDate(parsed)) {
    return parsed;
  }
  return undefined;
}

function formatDate(date, format, locale) {
  return dateFnsFormat(date, format, { locale });
}

const FORMAT = 'dd/MM/yyyy';

const RowTable = ({row}) => {
    return (
        <tr>
          <td>{row.starts}</td>
          <td>{row.comments}</td>
        </tr>
      );
}


const Reviews = (props) => {
    const [reportData, setReportData] = useState(null);
    const [filters, setFilters] = useState({
      filterBy: 'week',
      startDate: '',
      endDate: '',
      page:1
    });
  
    const handleUserFetch = useCallback(async () => {
      // params for fetchData
      // FETCH DATA, and setUserdata
  
      const report = await appAxios.get('/reviews',{
        params:filters
      });
      setReportData(report.data)
  
  
    }, [filters]);
  
    
  
    function handleFilterChange(e) {
      e.persist();
      setFilters((state) => ({ ...state, [e.target.value]: e.target.id }));
  
    }

    function handlePaginate(page){
      setFilters((state) => ({ ...state, page: page }));
    }
  
  
  
    useEffect(() => {
      handleUserFetch();
    }, [handleUserFetch]);
  
    return (
      <div className='min-h-screen custom-bg-secondary'>
        <Header />
  
        <div className='max-w-1200px mx-auto d-flex p-4 align-items-start'>
  
          <div className='pl-3 width-full'>
            <div className={`align-items-center ${css.Filters} mb-4`}>
              <div className='d-flex align-items-center text-white mb-3'>
                <p className='m-0 text-sm mr-2 font-weight-bold'>Filtrar por:</p>
                <button
                  className={`${css.FilterButton} mr-3 ${
                    filters.filterBy === 'week' && css['is-active']
                  }`}
                  type='button'
                  onClick={handleFilterChange}
                  value='filterBy'
                  id='week'>
                  ESTA SEMANA
                </button>
  
                <button
                  className={`${css.FilterButton} mr-3 ${
                    filters.filterBy === 'lastWeek' && css['is-active']
                  }`}
                  type='button'
                  onClick={handleFilterChange}
                  value='filterBy'
                  id='lastWeek'>
                  ÚLTIMA SEMANA
                </button>
  
                <button
                  className={`${css.FilterButton} mr-3 ${
                    filters.filterBy === 'month' && css['is-active']
                  }`}
                  type='button'
                  onClick={handleFilterChange}
                  value='filterBy'
                  id='month'>
                  MES ACTUAL
                </button>
  
                <button
                  className={`${css.FilterButton} ${
                    filters.filterBy === 'lastMonth' && css['is-active']
                  }`}
                  type='button'
                  onClick={handleFilterChange}
                  value='filterBy'
                  id='lastMonth'>
                  MES PASADO
                </button>
              </div>
  
              <div className='d-flex align-items-center'>
                {/* https://react-day-picker.js.org/examples/input/ */}
                <p className='m-0 text-sm mr-2 font-weight-bold text-white'>Desde:</p>
                <DayPickerInput
                  placeholder='dd/mm/yyyy'
                  formatDate={formatDate}
                  format={FORMAT}
                  parseDate={parseDate}
                  onDayChange={(day) =>
                    setFilters((state) => ({ ...state, startDate: day.toISOString() }))
                  }
                  inputProps={{ className: css.PickerInput }}
                />
  
                <p className='m-0 text-sm ml-3 mr-2 font-weight-bold text-white'>Hasta:</p>
                <DayPickerInput
                  placeholder='dd/mm/yyyy'
                  formatDate={formatDate}
                  format={FORMAT}
                  parseDate={parseDate}
                  onDayChange={(day) =>
                    setFilters((state) => ({ ...state, endDate: day.toISOString() }))
                  }
                  inputProps={{ className: css.PickerInput }}
                />
              </div>
            </div>
            <Row>
                <Col md={4} sm={6} xs={12}>
                <BoxInfo color='#4DE998' title='Calificación Global' value={reportData ? reportData["global"] : 0} />
                </Col>

                <Col md={4} sm={6} xs={12}>
                <BoxInfo color='#3DC1FC' title='Calificación actual' value={reportData ? reportData.now : 0} />
                </Col>
                
            </Row>

            <Row>

                <table class="table table-dark">
                    <thead>
                        <tr>
                            <th scope="col">Calificación</th>
                            <th scope="col">Comentario</th>
                        </tr>
                    </thead>
                    <tbody>
                    {reportData?.data?.map((row, i) => <RowTable key={i} row={row} />)}
                    </tbody>
                    <tfoot>
                        <tr>
                            <td colSpan="2">
                                <Paginate>
                                    <Paginate.Prev className={reportData?.links.prev ? '' :'disabled'}>Anterior</Paginate.Prev>
                                    {Array(reportData?.meta?.last_page).fill(1).map((el, i) =>
                                        <Paginate.Item onClick={(event) => handlePaginate(i+1)}  className={reportData?.meta?.from === (i+1) ? 'active' :'' } >
                                            {i+1}
                                        </Paginate.Item>
                                    )}
                                    
                                    <Paginate.Next className={reportData?.links?.next ? '' :'disabled'}>Siguiente</Paginate.Next>
                                    
                                </Paginate>
                            </td>
                        </tr>
                    </tfoot>
                </table>
            </Row>
            
          </div>
        </div>
      </div>
    );
  };
  
  export default Reviews;